<template>
  <v-container>
    <v-window v-model="step" touchless>
      <v-window-item :value="0">
        <v-row
          v-if="$vuetify.breakpoint.mobile===false"
          align="center"
        >
          <v-col cols="auto">
            <v-btn

              :text="true"
              :ripple="false"
              color="neutral-500"
              @click="$router.back()"
            >
              <v-icon left>
                $iconify_heroicons-outline-arrow-left
              </v-icon>
              <span class="">{{ $t('label.back') }}</span>
            </v-btn>
          </v-col>
          <v-col
            class="ml-2 text-h3 font-weight-bold"
          >
            {{ $t('label.sale') }}
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-form
              ref="form"
              v-model="valid"
            >
              <v-row>
                <v-col class="">
                  <shop-select
                    v-model="shopId"
                    hide-details
                    :chips="false"
                    :label="$t('label.shop')"
                    use-session
                    :rules="[
                      v=> !!v || 'Укажите торговую точку'
                    ]"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-form
                  ref="formValidAccount"
                  v-model="validAccount"
                >
                  <account-select
                    ref="accountSelect"
                    v-model="account"
                    return-object
                    barcode-reader
                    :autofocus="!account"
                    :label="$t('label.customer')"
                    :placeholder="$t('label.search_account')"
                    :with-deleted="false"
                    :rules="[
                      v=> !!v || $t('validation.required_customer')
                    ]"
                    @input="onInputAccount"
                  >
                    <template v-slot:append-item>
                      <v-divider class="" />
                      <v-list-item
                        class="primary--text"
                        @click="onAccountRegisterClick"
                      >
                        <v-list-item-icon>
                          <v-icon color="primary">
                            $iconify_plus-circle
                          </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content class="primary--text">
                          <v-list-item-title>
                            {{ $t('label.account_register') }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </account-select>
                  </v-form>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-form
                    ref="formValidSumField"
                    v-model="validSumField"
                   >
                  <base-money-number-field
                    :suffix="program.currency.symbol"
                    ref="saleSumField"
                    v-model="valueRub"
                    :label="$t('label.money_sum')"
                    :placeholder="$t('label.money_sum')"
                    @blur="( (saleCalcResult ? saleCalcResult.sum : null) !== valueCoin) && accountSaleCalcHandle(0)"
                    @keydown.enter="onKeydownEnterSumField"
                  />
                  </v-form>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-row>
                    <v-col>
                      <v-btn-toggle
                        ref="bonusOperationSelect"
                        v-model="bonusOperationType"
                        mandatory
                        color="primary"
                        group
                      >
                        <v-btn value="CREDIT">
                          {{ $t('credit_action') }}
                        </v-btn>
                        <v-btn value="DEBIT">
                          {{ $t('debit_action') }}
                        </v-btn>
                      </v-btn-toggle>
                    </v-col>
                  </v-row>
                  <v-row v-if="buyBonusRes.length > 1">
                    <v-col>
                      <v-select
                        v-model="selectedBonusRes"
                        :label="$t('label.bonus_res')"
                        :rules="selectedBonusResRules"
                        class=""
                        :items="buyBonusRes"
                        item-text="title"
                        outlined
                        hide-details
                        :loading="getBonusResListAction"
                        return-object
                        clearable
                        @change="accountSaleCalcHandle(0)"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row v-if="selectedBonusRes && valueRub && account">
                <v-col v-if="accountSaleCalcAction || saleCalcResult == null">
                  <v-skeleton-loader
                    type="image"
                  />
                </v-col>
                <v-col v-else-if="saleCalcResult">
                  <v-row class="body-m-semibold">
                    <v-col cols="auto">
                      {{ $t('label.balance') + ':' }}
                    </v-col>
                    <v-col>
                      {{ saleCalcResult.balance.toLocaleString() }}
                      {{ declOfNum(saleCalcResult.balance, mainBonusUnit.unit_name_ending || mainBonusUnit.name ) }}
                      </v-col>
                    <!-- <v-col>{{ account.main_balance.toLocaleString() }} {{ declOfNum(account.main_balance, mainBonusUnit.unit_name_ending || mainBonusUnit.name ) }}</v-col> -->
                  </v-row>
                  <v-row align="baseline" class="body-m-semibold">
                    <v-col class="d-flex align-center" cols="auto">
                      {{ $t('label.bonus') + ':' }}
                    </v-col>
                    <v-col style="min-width: 170px; max-width: 20em" :class="bonusValue >= 0 ? 'success--text' : 'error--text' ">
                      <div v-if="bonusOperationType == 'DEBIT' && statusInputBonusDebit">
                        <v-form
                          ref="formValidBonusDebit"
                          v-model="validInputBonusDebit"
                        >
                          <base-text-field
                            hide-details="auto"
                            error-style="vuetify"
                            @input="accountSaleCalcHandle(1000, false, true)"
                            v-model="InputBonusDebit"
                            prefix="-"
                            :suffix="declOfNum(saleCalcResult.balance, mainBonusUnit.unit_name_ending || mainBonusUnit.name )"
                            :rules="[
                              v => v <= maxInputBonusDebit|| 'Не более ' + maxInputBonusDebit,
                              v => v > 0 || 'Должно быть больше 0'
                            ]"
                          />
                          </v-form>
                      </div>
                      <div :class="bonusOperationType == 'DEBIT' ? 'error--text' : '' " v-else >
                        <a v-if="bonusOperationType == 'DEBIT' && InputBonusDebit > 0" class="error--text" style="border-bottom: 1px dashed #ea4c2a;" @click="statusInputBonusDebit = true">
                          <span> {{ bonusValueStr }}</span>
                        </a>
                        <span v-else>
                          {{ bonusValueStr }}
                        </span>
                        <span> 
                          {{ declOfNum(bonusValue, mainBonusUnit.unit_name_ending || mainBonusUnit.name ) }} 
                        </span>
                        <span v-if="bonusPercent">({{ bonusPercent }}%)</span>
                        <span v-else>{{percentageAccrual}}</span>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row class="body-m-semibold">
                    <v-col cols="auto">
                      {{ $t('label.discount') + ':' }}
                    </v-col><v-col>{{ discountStr }}</v-col>
                  </v-row>
                  <v-row class="body-m-semibold">
                    <v-col cols="auto">
                      {{ $t('label.total') + ':' }}
                    </v-col><v-col>{{ totalStr }}</v-col>
                  </v-row>                 
                </v-col>
              </v-row>
              <v-row>
                    <v-col cols="12">
                      <v-btn
                        v-if="!statusInputComment"
                        style="margin-left: 3px;"
                        color="secondary"
                        :text="true"
                        :ripple="false"
                        @click="statusInputComment = true"
                      >
                        <v-icon left>
                          $iconify_plus-circle-outlined
                        </v-icon>
                        Добавить комментарий
                      </v-btn>
                      <v-textarea
                        v-if="statusInputComment"
                        v-model="comment"
                        :required="false"
                        outlined
                        clearable
                        :label="$t('label.comment')"
                        :placeholder="$t('label.comment_placeholder_nullable')"
                        maxlength="255"
                        counter="255"
                      ></v-textarea>
                    </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="auto">
                  <v-btn
                    ref="submitBtn"
                    color="primary"
                    class="mb-3"
                    :disabled="!valid ||createAccountSaleAction || accountSaleCalcAction || saleCalcResult === null || !validInputBonusDebit"
                    :loading="createAccountSaleAction"
                    x-large
                    @click="createAccountSaleClick()"
                  >
                    <v-icon left>
                      $iconify_ion-checkmark-circle-outline
                    </v-icon>{{ $t('label.create') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </v-window-item>

      <v-window-item :value="1">
        <v-card
          v-if="sale"
          class="mb-3 secondary"
          elevation="3"
          outlined
        >
          <v-card-text>
            <v-row justify="center">
              <v-col
                cols="auto"
                class="text-h3 text-uppercase"
              >
                {{ this.$t('sale_id', { saleId: sale.id }), }}
              </v-col>
            </v-row>
            <v-row><v-col><v-divider /></v-col></v-row>
            <v-row>
              <v-col
                cols="12"
                class=" text-uppercase"
              >
                {{ $t('label.customer') }}
              </v-col>
              <v-col
                cols="12"
                class="font-weight-bold"
              >
                {{ account.client_name_display }}
              </v-col>
            </v-row>
            <v-row><v-col><v-divider /></v-col></v-row>
            <v-row justify="space-between">
              <v-col
                cols="auto"
                class="text-uppercase"
              >
                {{ $t('label.money_sum') }}
              </v-col>
              <v-col
                cols="auto"
                class="font-weight-bold"
              >
                {{ sumStr }}
              </v-col>
            </v-row>
            <v-row
              justify="space-between"
            >
              <v-col
                cols="auto"
                class="text-uppercase"
              >
                {{ $t('label.bonus') }}
              </v-col>
              <v-col
                cols="auto"
                :class="['font-weight-bold', bonusValue >= 0 ? 'success--text' : 'error--text'] "
              >
                <span>{{ bonusValueStr }}</span> <span>
                  {{ declOfNum(bonusValue, mainBonusUnit.unit_name_ending || mainBonusUnit.name ) }}</span> 
                  <span v-if="bonusPercent">({{ bonusPercent }}%)</span>
              </v-col>
            </v-row>
            <v-row justify="space-between">
              <v-col
                cols="auto"
                class="text-uppercase"
              >
                {{ $t('label.discount') }}
              </v-col>
              <v-col
                cols="auto"
                class="font-weight-bold"
              >
                {{ discountStr }}
              </v-col>
            </v-row>
            <v-row><v-col><v-divider /></v-col></v-row>
            <v-row
              justify="space-between"
            >
              <v-col
                cols="auto"
                class="text-uppercase text-h3"
              >
                {{ $t('label.to_pay') }}
              </v-col>
              <v-col
                cols="auto"
                class="font-weight-bold text-h3"
              >
                {{ totalStr }}
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-row justify="center">
          <v-col class="text-center ">
            <v-btn
              color="success"
              x-large
              @click="resetForm()"
            >
              {{ $t('label.new_sale') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-window-item>
    </v-window>
    <side-panel-new-client
        style="z-index: 9"
        v-model="sidePanelNewClientStatus"
        :showDescription="false"
        @dataCreateClient="createClient"
    />
  </v-container>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import ConvertorMixin from '@/mixins/convertor'
  import currencyMixin from '@/mixins/currency'
  // import BonusUnit from '@/models/program/BonusUnit'
  import { CancelToken } from 'axios'

  // const CancelToken = axios.CancelToken

  export default {
    components: {
      // CertificateUserBlock: () => import('@/views/account/certificate/CertificateUserBlock.vue'),
      ShopSelect: () => import('@/components/ModelSelect/ShopSelect'),
      AccountSelect: () => import('@/components/ModelSelect/AccountSelect'),
      SidePanelNewClient: () => import('@/views/crm/client/components/SidePanelNewClient')
      // AccountSelect: () => import('@/components/ModelSelect/AccountSelect'),
    },
    mixins: [ConvertorMixin, currencyMixin],
    props: {
      accountId: {type: Number, default: null}
    },
    data () {
      return {
        sidePanelNewClientStatus: false,
        step: 0,
        cancelTokenSource: CancelToken.source(),
        valid: false,
        validAccount: false,
        validSumField: false,
        validInputBonusDebit: true,
        bonusOperationType: 'CREDIT',
        createAccountSaleAction: false,
        accountSaleCalcAction: false,
        accountSaleCalcActionId: null,
        getBonusResListAction: false,
        selectedBonusRes: null,
        valueRub: null,
        shopId: null,
        account: null,
        comment: null,
        withComment: false,
        delayedRecalcTask: null,
        commentRules: [
          v => String(v).length <= 255 || 'Не более 255 символов',
        ],
        selectedBonusResRules: [
          v => !!v || this.$t('validation.required_bonus_res'),
        ],
        saleCalcResult: null,
        sale: null,
        statusInputBonusDebit: false,
        statusInputComment: false,
        InputBonusDebit: null,
        maxInputBonusDebit: 0
      }
    },
    computed: {
      ...mapGetters({
        activeBuyBonusRes: 'company/bonus_resources/activeBuyBonusRes',
        mainBonusUnit: 'company/bonus_units/mainBonusUnit',
        programId: 'programId',
      }),
      program () {
        return this.$store.getters['company/program/program']
      },
      buyBonusRes () {
        return this.activeBuyBonusRes
          .filter(item => this.mainBonusUnit && item.bonus_score.units_id === this.mainBonusUnit.id)
          .filter(item => item.can_app_usage === true)
          .filter((item) => this.bonusOperationType === 'CREDIT' ? item.resource_type_enum === 'TYPE_SOURCE' : item.resource_type_enum === 'TYPE_TARGET')
      },
      valueCoin () { return Math.trunc(this.valueRub * 100) },
      operationTypeText () {
        return this.selectedBonusRes.resource_type_enum === 'TYPE_TARGET' ? 'списано' : 'начислено'
      },
      // selectedBonusUnit () {
      //   if (this.selectedBonusRes) {
      //     const index = this.accountBalances.findIndex(item => item.unit_id === this.selectedBonusRes.bonus_score.units_id)
      //     return index >= 0 ? new BonusUnit(this.accountBalances[index]) : null
      //   } else return null
      // },
      // selectedBonusUnitEndings () {
      //   return this.selectedBonusUnit && this.selectedBonusUnit.ending ? this.selectedBonusUnit.ending : null
      // },
      // selectedBonusUnitBalance () {
      //   return this.selectedBonusUnit ? Number(this.selectedBonusUnit.balance) : null
      // },
      // selectedBonusUnitBalanceStr () {
      //   return this.selectedBonusUnitBalance !== null ? this.selectedBonusUnitBalance.toLocaleString() : null
      // },
      bonusPercent () {
        return this.saleCalcResult ? this.saleCalcResult.bonus_discount_percent : null
      },
      bonusDirection () {
        return this.selectedBonusRes.resource_type_enum === 'TYPE_TARGET' ? 'FROM' : 'TO'
      },
      bonusValue () {
        return this.saleCalcResult ? this.saleCalcResult.bonus_value : null
      },
      bonusValueStr () {
        return this.bonusValue > 0 ? '+' + this.bonusValue.toLocaleString() : this.bonusValue.toLocaleString()
      },
      // discountValue(){
      //   return this.selectedBonusRes && this.selectedBonusRes.type == 'TYPE_TARGET' ?
      // },
      totalRub () {
        return this.saleCalcResult ? this.saleCalcResult.total / 100 : this.valueRub
      },
      discountRub () {
        return this.saleCalcResult ? this.saleCalcResult.total_discount / 100 : 0
      },
      discountStr () {
        return (-this.discountRub).toLocaleString(undefined, {style: this.program.currency.alpha3 !=='XXX' ? 'currency': 'decimal' , currency: this.program.currency.alpha3})
      },
      sumStr () {
        return this.valueRub !== null ? this.valueRub.toLocaleString(undefined, {style: this.program.currency.alpha3 !=='XXX' ? 'currency': 'decimal' , currency: this.program.currency.alpha3}) : ''
      },
      totalStr () {
        return this.totalRub !== null ? this.totalRub.toLocaleString(undefined, {style: this.program.currency.alpha3 !=='XXX' ? 'currency': 'decimal' , currency: this.program.currency.alpha3}) : ''
      },
      percentageAccrual () {
        if (this.InputBonusDebit > 0 && this.totalRub > 0) {
          return "(" + (this.InputBonusDebit * 100 / this.totalRub).toFixed(0) + "%)"
        } else {
          return ''
        }
      }
    },
    watch: {
      programId () {
        this.init()
      },
      shopId () {
        if (this.validAccount || this.validSumField) {
          this.InputBonusDebit = null
          this.selectedBonusRes = null
          this.autoSelectBonusRes()
          this.accountSaleCalcHandle()
        }
      },
      valueCoin (v) {
        if (v > 0 && (this.saleCalcResult?.sum !== v)) this.accountSaleCalcHandle(1000)
      },
      bonusOperationType () {
        this.InputBonusDebit = null
        this.selectedBonusRes = null
        this.autoSelectBonusRes()
        this.accountSaleCalcHandle()
      },
    },
    created () {
      this.init()
    },
    mounted () {
      if (this.accountId) {
        if (!this.account) {
          this.account = {
            id : this.accountId
          }
        }
      }
    },
    methods: {
      ...mapActions({
        getBonusResList: 'company/bonus_resources/GetList',
        loadBonusUnits: 'company/bonus_units/loadBonusUnits',
        createAccountSale: 'company/sale/createAccountSale',
        accountSaleCalc: 'company/sale/accountSaleCalc',
        transactionsAdd: 'crm/clientCard/transactionsAdd',
      }),
      async init () {
        try {
          this.initAction = true
          await this.getBonusResList(this.programId)
          await this.loadBonusUnits(this.programId)
          this.autoSelectBonusRes()
        } catch (error) {
          console.error(error)
        } finally {
          this.initAction = false
        }
      },
      resetForm () {
        this.step = 0
        this.saleCalcResult = null
        this.sale = null
        this.account = null
        this.valueRub = null
        this.comment = null
        this.statusInputComment = false
        this.$refs.form.resetValidation()
        this.$refs.accountSelect.focus()
      },
      onInputAccount (v) {
        if (v) {
          this.$vuetify.goTo(this.$refs.saleSumField)
          this.$refs.saleSumField.focus()
          if (this.validAccount || this.validSumField) {
            this.InputBonusDebit = null
            this.accountSaleCalcHandle()
          }
        }
      },
      onKeydownEnterSumField (e) {
        if (this.valid) {
          this.$refs.saleSumField.blur()
          this.$vuetify.goTo(this.$refs.submitBtn)
        }
      },
      onAccountRegisterClick () {
        this.sidePanelNewClientStatus = true
      },
      autoSelectBonusRes () {
        if (this.buyBonusRes.length === 1) this.selectedBonusRes = this.buyBonusRes[0]
      },
      async  loadBonusRes () {
        try {
          this.getBonusResListAction = true
          await this.getBonusResList(this.programId)
          this.autoSelectBonusRes()
        } catch (error) {
          console.error(error)
        } finally {
          this.getBonusResListAction = false
        }
      },
      async  accountSaleCalcHandle (delay = 0, oneRequest = true, onInput = false) {
        console.log('selectedBonusRes', this.selectedBonusRes)
        console.log('accountSaleCalcHandle', this.accountSaleCalcAction, delay)
        console.log('oneRequest', oneRequest)

        if (this.accountSaleCalcActionId) {
          clearTimeout(this.accountSaleCalcActionId)
        }

        if (delay > 0) {
          this.accountSaleCalcActionId = setTimeout(() => {
            this.accountSaleCalcHandle(0, oneRequest, onInput)
          }, delay)
          return
        }

        if (!this.$refs.formValidAccount.validate() || 
            !this.$refs.formValidSumField.validate() || 
            !this.selectedBonusRes) return

        if (onInput && this.statusInputBonusDebit && !this.$refs.formValidBonusDebit.validate()) return

        if (!this.validInputBonusDebit || oneRequest) this.InputBonusDebit = null

        if (this.accountSaleCalcAction) {
          this.cancelTokenSource.cancel('Operation canceled ')
        }

        try {
          this.accountSaleCalcAction = true
          this.cancelTokenSource = CancelToken.source()
          this.saleCalcResult = null
          this.saleCalcResult = await this.accountSaleCalc({
            request: {
              shop_id: this.shopId,
              account_id: this.account.id,
              sum: this.valueCoin,
              bonus_res_id: this.selectedBonusRes.id,
              bonus_value: this.InputBonusDebit
            },
            cancelToken: this.cancelTokenSource.token,
          })
          this.InputBonusDebit = Math.abs(this.saleCalcResult.bonus_value)
          if (oneRequest) this.maxInputBonusDebit = Math.abs(this.saleCalcResult.bonus_value)
          this.statusInputBonusDebit = false
        } catch (error) {
          console.error(error)
        } finally {
          this.accountSaleCalcAction = false
        }
      },
      async onSaleCreated (sale) {
        this.sale = sale
        this.step = 1

        // success
        // try {
        //   await this.$alert(
        //     this.$t('sale_fixed'),
        //     this.$t('sale_id', { saleId: sale.id }),
        //     {
        //       confirmButtonText: this.$t('label.new_sale'),
        //       // cancelButtonText: this.$t('label.back'),
        //       type: 'success',
        //     },
        //   )
        //   // this.$refs.form.reset()
        // } catch {
        //   // this.$router.back()
        // } finally {
        //   this.resetForm()
        // }
      },
      async createAccountSaleClick () {
        if (!this.$refs.form.validate()) return

        try {
          this.createAccountSaleAction = true
          // фиксация продажи
          const sale = await this.createAccountSale({
            shop_id: this.shopId,
            account_id: this.account.id,
            value: this.valueCoin,
            comment: this.comment,
            bonus_res_id: this.selectedBonusRes.id,
          })
          // бон операция по продаже
          if (this.selectedBonusRes && this.bonusValue !== 0) {
            const reqData = {
              account_id: this.account.id,
              direction: this.bonusDirection,
              value: Math.abs(this.bonusValue),
              sale_id: sale.id,
              comment: this.comment,
            }
            if (this.bonusDirection === 'TO') {
              reqData.score_source_id = this.selectedBonusRes.score_id
            } else {
              reqData.score_target_id = this.selectedBonusRes.score_id
            }
            await this.transactionsAdd(reqData)
          }
          // this.close()
          this.onSaleCreated(sale)
        } catch (error) {
          console.error(error)
          throw error
        } finally {
          this.createAccountSaleAction = false
        }
      },
      createClient(client)
      {
        this.$refs.accountSelect.init()
        this.account = client
        this.accountSaleCalcHandle(0)
      }
    },
  }
</script>

<style lang="scss">
@import "@/styles/vuetify-preset-plus/light_theme/crm/components/side_panels/_side-panel-new-client.scss";
</style>
